import { useEffect, useState } from 'react'

interface Section {
	id: string
	offsetTop: number
	clientHeight: number
}

const useScrollSpy = (selector: string): string => {
	const [prevSection, setPrevSection] = useState<string>('')

	useEffect(() => {
		const handleScroll = () => {
			const sections: Section[] = []

			document.querySelectorAll(selector).forEach((section: HTMLElement) => {
				sections.push({ id: section.id, offsetTop: section.offsetTop, clientHeight: section.clientHeight })
			})

			const scrollY = window.scrollY
			let currentSection = ''

			sections.forEach(section => {
				const sectionTop = section.offsetTop
				const sectionBottom = sectionTop + section.clientHeight

				if (scrollY >= sectionTop - window.innerHeight / 2 && scrollY < sectionBottom) {
					currentSection = section.id
				}
			})

			if (currentSection !== prevSection) {
				setPrevSection(currentSection)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [prevSection, selector])

	return prevSection
}

export default useScrollSpy
