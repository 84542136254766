'use client'
import { Icons } from 'components/common/icons'
import useScrollSpy from 'core/hooks/use-scroll-spy'
import { cn } from 'core/cn'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { useState } from 'react'

export default function Sidebar() {
	const activeSection = useScrollSpy('section')
	const pathname = usePathname()
	const [active, setActive] = useState(false)
	return (
		<div className="relative z-[49] bg-white shadow-md sm:sticky sm:top-0">
			<div className="sticky top-0 z-1 flex h-screen flex-col items-center justify-between p-6 sm:h-12 sm:flex-row sm:px-[30px] xs:px-3">
				<div className="p-3 sm:p-0 sm:pl-[50px]">
					<Link
						href="/"
						title="ADV Biotech Solutions Trading Ltd"
						className="logo block h-[132px] w-[24px] sm:origin-center sm:rotate-90"
					>
						<Icons.logo />
					</Link>
				</div>
				<nav className="menu mt-auto sm:mt-0 xs:hidden">
					<ul className="sm:flex sm:gap-6">
						<li className="menu-item">
							<Link href="/products" className={cn(`menu-item-link`, { active: pathname === '/products' })}>
								Our products
							</Link>
						</li>
						<li className="menu-item">
							<Link href="/#about" className={cn(`menu-item-link`, { active: activeSection == 'about' })}>
								About Us
							</Link>
						</li>
						<li className="menu-item">
							<Link href="/#contacts" className={cn(`menu-item-link`, { active: activeSection == 'contacts' })}>
								Contacts
							</Link>
						</li>
					</ul>
				</nav>
				<DropdownMenu modal={false} onOpenChange={open => setActive(open)}>
					<DropdownMenuTrigger className={cn('hidden xs:block', { 'text-primary': active })}>
						<Icons.menu />
					</DropdownMenuTrigger>
					<DropdownMenuContent sideOffset={14} align="end" alignOffset={0} className="grid gap-4 rounded-lg py-4">
						<DropdownMenuItem asChild>
							<Link href="/products" className={cn(`menu-item-link`, { active: pathname === '/products' })}>
								Our products
							</Link>
						</DropdownMenuItem>
						<DropdownMenuItem asChild>
							<Link href="/#about" className={cn(`menu-item-link`, { active: activeSection == 'about' })}>
								About Us
							</Link>
						</DropdownMenuItem>
						<DropdownMenuItem asChild>
							<Link href="/#contacts" className={cn(`menu-item-link`, { active: activeSection == 'contacts' })}>
								Contacts
							</Link>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
}
