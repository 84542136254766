'use client'
import Link from 'next/link'

export default function Footer() {
	return (
		<div className="relative flex flex-col gap-12 bg-black p-[120px] text-sm text-white 2xl:p-[80px] xl:p-[60px] sm:gap-6 sm:p-[30px]">
			<p>
				The site is under construction; new information will be added soon. If you have any questions concerning
				potential partnership, please go to Contacts section.
			</p>
			<div className="flex justify-between gap-12 sm:flex-col sm:gap-6">
				<p>© ADV Biotech Solutions Trading, Ltd., {new Date().getFullYear()}</p>
				<div className="flex gap-6">
					<Link href="/user-agreement">User Agreement</Link>
					<Link href="/user-agreement/cookies">Cookies</Link>
				</div>
			</div>
		</div>
	)
}
